import axios from "axios";

export async function GetCategorias(user) {
  try {
    const response = await axios.get(
      `http://localhost:3000/api/dashboard/categoria/${user}`
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function PostProduto(body) {
  try {
    const response = await axios.post(
      `http://localhost:3000/api/dashboard/produto/alteracao`,
      body
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
