import { useEffect, useRef, useState } from "react";
import { PostProduto } from "./function";

export function ComponenteProdutosTabela({ produto, categoria }) {
  console.log(produto);
  const [NomeProduto, setNomeProduto] = useState(produto.nome_item);
  const [Descricao, setDescricao] = useState(produto.descricao_item);
  const [Valor, setValor] = useState(produto.preco_item);
  const [ValorDelicery, setValorDelicery] = useState(produto.valor_delivery);
  const [ValorPromocional, setValorPromocional] = useState(produto.promo_valor);
  const [Status, setStatus] = useState(produto.disponivel == 1);
  const [Categoria, setCategoria] = useState(produto.id_cat);
  const isFirstRender = useRef(true);

  function AtualizaValor(Campo, Valor) {
    let body = { codigo: produto.id, campo: Campo, valor: Valor };

    PostProduto(body);
  }

  function AtualizaDescricao() {
    AtualizaValor("descricao_item", `'${Descricao}'`);
  }

  function AtualizaNome() {
    AtualizaValor("nome_item", `'${NomeProduto}'`);
  }

  function AtualizarValor(campo, valor) {
    AtualizaValor(campo, parseFloat(valor));
    return true;
  }

  function AtualizaCategoria(categoria) {
    setCategoria(categoria);
    AtualizaValor("id_cat", categoria);
  }

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    AtualizaValor("disponivel", Status ? 1 : 0);

    // Código que você deseja executar após a primeira renderização
    console.log(Status);
  }, [Status]);

  return (
    <>
      <tr>
        <td
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={`https://fotos.goopedir.com/fotos/${btoa(produto.id)}`}
            alt="Icon"
            // style={data.site > 2 ? iconStyleOff : iconStyleOn}
          />
        </td>
        <td>
          <select
            name="cars"
            id="cars"
            style={{ width: "100%" }}
            onChange={(e) => AtualizaCategoria(e.target.value)}
          >
            {/* {categoria} */}
            {categoria?.map((item) => (
              <option value={item.id} selected={Categoria === item.id}>
                {item.nome_cat}
              </option>
            ))}
          </select>
        </td>
        <td>
          <div>
            <p>Nome</p>
            <input
              type="text"
              name="nome_produto"
              placeholder="Nome do Produto"
              value={NomeProduto}
              onChange={(e) => setNomeProduto(e.target.value)}
              onBlur={AtualizaNome}
              style={{ width: "100%" }}
            />
          </div>

          <div>
            <p>Descrição</p>
            <input
              type="text"
              name="nome_produto"
              placeholder="Descricao"
              value={Descricao}
              onChange={(e) => setDescricao(e.target.value)}
              onBlur={AtualizaDescricao}
              style={{ width: "100%" }}
            />
          </div>
        </td>
        <td>
          <div>
            <div>
              <p>Valor</p>
              <input
                type="number"
                name="valor_produto"
                placeholder="Valor do Produto"
                value={Valor}
                onChange={(e) => setValor(e.target.value)}
                onBlur={() => AtualizarValor("preco_item", Valor)}
                style={{ width: "100%" }}
              />
            </div>

            <div>
              <p>Adicional Delivery</p>
              <input
                type="number"
                name="valor_delivery"
                placeholder="Adicional Delivery"
                value={ValorDelicery}
                onChange={(e) => setValorDelicery(e.target.value)}
                onBlur={() => AtualizarValor("valor_delivery", ValorDelicery)}
                style={{ width: "100%" }}
              />
            </div>

            <div>
              <p>Valor Promocional</p>
              <input
                type="number"
                name="promo_valor"
                placeholder="Valor do Produto"
                value={ValorPromocional}
                onChange={(e) => setValorPromocional(e.target.value)}
                onBlur={() => AtualizarValor("promo_valor", ValorPromocional)}
                style={{ width: "100%" }}
              />
            </div>
          </div>
        </td>
        <td>
          <input
            type="checkbox"
            name="status_produto"
            checked={Status}
            onChange={(e) => setStatus(!Status)}
          />{" "}
          Ativo/Inativo
        </td>
      </tr>
    </>
  );
}
