import { Header } from "../../component/header";
import VersoesTable from "./component/tabela";

export function Atualizacao() {
  return (
    <>
      <Header />
      <VersoesTable />
    </>
  );
}
