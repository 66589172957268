import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button"; // Importando o botão do PrimeReact
import axios from "axios";
import "./VersoesTable.css"; // Adicione uma folha de estilo personalizada (opcional)
import VersaoModal from "../modal/indes";

const VersoesTable = () => {
  const [versoes, setVersoes] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    axios
      .get("http://localhost/backend/api/versao/listar.php")
      .then((response) => setVersoes(response.data))
      .catch((error) => console.error("Erro ao carregar versões:", error));
  }, []);

  const handleNovaVersao = () => {
    // Adicione a lógica de navegação ou abertura de modal para cadastrar nova versão
    setModalVisible(true);
    console.log("Cadastro de nova versão");
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  return (
    <div className="versoes-container">
      <div className="header-container">
        <h1>Versões Cadastradas</h1>
        <Button
          label="Nova Versão"
          icon="pi pi-plus"
          className="p-button-success"
          onClick={handleNovaVersao}
        />
      </div>
      <DataTable
        value={versoes}
        paginator
        rows={10}
        className="versoes-table"
        responsiveLayout="scroll"
        emptyMessage="Nenhuma versão encontrada."
      >
        <Column field="nome" header="Nome" sortable />
        <Column field="data_criacao" header="Data de Criação" sortable />
        <Column field="data_liberacao" header="Data de Liberação" sortable />
        <Column field="clientes_liberados" header="Clientes Liberados" />
        <Column field="clientes_atualizados" header="Clientes Atualizados" />
      </DataTable>
      <VersaoModal visible={modalVisible} onHide={handleCloseModal} />
    </div>
  );
};

export default VersoesTable;
