import React, { useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import useFetch, { PostData } from "../../../component/conexao";

function TabelaClientes() {
  const { data, loading, error } = useFetch(
    "https://goopedir.com/ws/v1/cliente.php"
  ); // Substitua pela URL real da sua API
  const [globalFilter, setGlobalFilter] = useState("");
  const StyleAbertoOpen = {
    backgroundColor: "green",
    borderRadius: "10px 0px 0px 10px",
    width: "80px",
    color: "white",
    border: "1px solid rgba(0,0,0,0.5)",
  };

  const StyleAbertoClose = {
    backgroundColor: "rgba(207, 207, 207,0.5)",
    borderRadius: "10px 0px 0px 10px",
    width: "80px",
    border: "1px solid rgba(0,0,0,0.5)",
  };

  const StyleFechadoOpen = {
    backgroundColor: "rgb(168, 0, 28)",
    borderRadius: "0px 10px 10px 0px",
    color: "white",
    width: "80px",
    border: "1px solid rgba(0,0,0,0.5)",
  };

  const StyleFechadoClose = {
    backgroundColor: "rgba(207, 207, 207,0.5)",
    borderRadius: "00px 10px 10px 00px",
    width: "80px",
    border: "1px solid rgba(0,0,0,0.5)",
  };

  function Abrir() {
    let body = { usuario: data.user_id, campo: "status_loja", valor: 1 };
    PostData("https://goopedir.com/ws/v2/alteracao-empresa.php", body);
    //   setStatusLoja(true);
  }

  function Fechar() {
    let body = { usuario: data.user_id, campo: "status_loja", valor: 0 };
    PostData("https://goopedir.com/ws/v2/alteracao-empresa.php", body);
    //   setStatusLoja(false);
  }

  const iconStyleOn = {
    // flex: '0 0 150px',
    width: "40px",
    height: "40px",
    marginRight: "20px",
    borderRadius: "150px",
    border: "3px solid green",
  };

  const iconStyleOff = {
    // flex: '0 0 150px',
    width: "40px",
    height: "40px",
    marginRight: "20px",
    borderRadius: "150px",
    border: "3px solid rgb(194, 0, 29)",
  };

  if (loading) return <p>Carregando...</p>;
  if (error) return <p>Erro ao carregar dados!</p>;

  // Template para o Status do Site
  const statusSiteTemplate = (rowData) => {
    return (
      <div style={{ display: "flex", textAlign: "center" }}>
        <div
          onClick={Abrir}
          style={rowData.status_loja == 1 ? StyleAbertoOpen : StyleAbertoClose}
        >
          Aberto
        </div>
        <div
          style={
            rowData.status_loja == 1 ? StyleFechadoClose : StyleFechadoOpen
          }
          onClick={Fechar}
        >
          Fechado
        </div>
      </div>
    );
  };

  // Template para o Vencimento
  const vencimentoTemplate = (rowData) => {
    const date = new Date(rowData.empresa_data_renovacao);
    return date.toLocaleDateString("pt-BR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  };

  // Template para o Ícone (Logo da Empresa)
  const iconTemplate = (rowData) => (
    <img
      src={`https://fotos.goopedir.com/fotos/empresa/${btoa(rowData.user_id)}`}
      alt="Logo"
      //   style={{ width: "40px", height: "40px", borderRadius: "4px" }}
      style={rowData.site > 2 ? iconStyleOff : iconStyleOn}
    />
  );

  return (
    <div style={{ width: "100%", padding: "20px" }}>
      {/* Campo de Pesquisa */}
      <div style={{ marginBottom: "1rem", width: "100%" }}>
        <InputText
          style={{ width: "100%" }}
          placeholder="Pesquisar..."
          value={globalFilter}
          onChange={(e) => setGlobalFilter(e.target.value)}
        />
      </div>

      {/* Tabela */}
      <DataTable
        value={data}
        paginator
        rows={10}
        globalFilter={globalFilter}
        responsiveLayout="scroll"
        style={{ width: "100%" }}
      >
        <Column header="" body={iconTemplate} />
        <Column field="nome_empresa" header="Empresa" />
        <Column field="client_id" header="Client ID" />
        <Column field="client_security" header="Client Security" />
        <Column header="Vencimento" body={vencimentoTemplate} />
        <Column header="Status Site" body={statusSiteTemplate} />
      </DataTable>
    </div>
  );
}

export default TabelaClientes;
