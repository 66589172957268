import { useParams } from "react-router-dom";
import useFetch from "../../component/conexao";
import { Header } from "../../component/header";
import { ComponenteProdutosTabela } from "./componente/produtos";
import { useEffect, useState } from "react";
import { GetCategorias } from "./componente/produtos/function";

export function PageProduct() {
  const [produtos, setProdutos] = useState([]);
  const [categoria, setCategoria] = useState([]);
  const { codigo } = useParams();
  const { data, loading, error } = useFetch(
    `http://localhost:3000/api/dashboard/produto/${codigo}`
  );

  useEffect(() => {
    if (data) {
      setProdutos(data);

      const fetchCategorias = async () => {
        try {
          let dados2 = await GetCategorias(codigo);
          console.log(dados2);
          setCategoria(dados2);
        } catch (error) {
          console.error("Failed to fetch categorias:", error);
        }
      };

      fetchCategorias();
    }
  }, [data, codigo]);

  // useEffect(() => {
  //   if (dataCategoria) {
  //     setCategoria(dataCategoria);
  //     console.log(dataCategoria);
  //   }
  // }, [dataCategoria]);

  //
  return (
    <>
      <Header descricao="Produtos" />

      <table>
        <thead>
          <tr>
            <th>Imagem</th>
            <th>Categoria</th>
            <th>Nome do Produto</th>
            <th>Valor do Produto</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {produtos?.map((produto) => (
            <ComponenteProdutosTabela produto={produto} categoria={categoria} />
          ))}

          {/* Adicione mais linhas conforme necessário */}
        </tbody>
      </table>
    </>
  );
}
