import React, { useState } from "react";
import { Sidebar } from "primereact/sidebar";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";
import { Dialog } from "primereact/dialog";
import { ListBox } from "primereact/listbox";
import { InputTextarea } from "primereact/inputtextarea";
import JSZip from "jszip";
// import { saveAs } from "file-saver"; // Para salvar o arquivo zip

const VersaoModal = ({ visible, onHide }) => {
  const [versao, setVersao] = useState("");
  const [descricao, setDescricao] = useState("");
  const [arquivos, setArquivos] = useState([]);
  const [showDialog, setShowDialog] = useState(false);
  const [currentFiles, setCurrentFiles] = useState([]);
  const [categoria, setCategoria] = useState("");
  const [localDestino, setLocalDestino] = useState("");

  const handleFileSelect = (e) => {
    const files = e.files;
    setCurrentFiles(files);
    setShowDialog(true);
  };

  const handleFolderSelect = async (e) => {
    const files = Array.from(e.target.files);
    const zip = new JSZip();
    const folder = zip.folder("selected_folder"); // Nome da pasta dentro do zip

    files.forEach((file) => {
      folder.file(file.webkitRelativePath, file);
    });

    try {
      const content = await zip.generateAsync({ type: "blob" });
      const zipFileName = `temp_folder_${Date.now()}.zip`;
      // saveAs(content, zipFileName);
      const newFile = {
        name: zipFileName,
        categoria: "Pasta",
        local: "Raiz",
        file: content,
      };
      setArquivos((prevArquivos) => [...prevArquivos, newFile]);
    } catch (error) {
      console.error("Erro ao criar ZIP:", error);
    }
  };

  const handleCategoriaConfirm = () => {
    const newFiles = currentFiles.map((file) => ({
      name: file.name,
      categoria,
      local: categoria === "Secundario" ? localDestino || file.path : "Raiz",
    }));
    setArquivos((prevArquivos) => [...prevArquivos, ...newFiles]);
    setShowDialog(false);
    setCurrentFiles([]);
    setCategoria("");
    setLocalDestino("");
  };

  const handleRemoveFile = (fileName) => {
    setArquivos((prevArquivos) =>
      prevArquivos.filter((file) => file.name !== fileName)
    );
  };

  return (
    <Sidebar
      visible={visible}
      onHide={onHide}
      position="right"
      className="p-sidebar-lg"
    >
      <h3>Cadastrar Nova Versão</h3>
      <div className="p-field">
        <label htmlFor="versao">Versão</label>
        <InputText
          id="versao"
          value={versao}
          onChange={(e) => setVersao(e.target.value)}
          placeholder="Digite a versão"
        />
      </div>
      <div className="p-field">
        <label htmlFor="descricao">Descrição</label>
        <InputTextarea
          id="descricao"
          value={descricao}
          onChange={(e) => setDescricao(e.target.value)}
          placeholder="Digite a descrição"
          rows={3}
        />
      </div>
      <div className="p-field">
        <FileUpload
          mode="basic"
          name="demo[]"
          accept="*"
          maxFileSize={1000000}
          chooseLabel="Selecionar Arquivos"
          customUpload={true}
          auto={false}
          multiple // Permite selecionar múltiplos arquivos
          onSelect={handleFileSelect}
        />
        <input
          type="file"
          webkitdirectory="true"
          mozdirectory="true"
          directory="true"
          multiple
          style={{ display: "none" }}
          id="folderInput"
          onChange={handleFolderSelect}
        />
        <Button
          label="Selecionar Pasta"
          icon="pi pi-folder-open"
          className="p-button-outlined"
          onClick={() => document.getElementById("folderInput").click()}
        />
      </div>
      <div className="p-field">
        <h4>Arquivos Selecionados</h4>
        <ListBox
          value={arquivos}
          options={arquivos}
          optionLabel="name"
          itemTemplate={(item) => (
            <div className="p-d-flex p-ai-center p-jc-between">
              <span>
                {item.name} ({item.categoria} - {item.local})
              </span>
              <Button
                icon="pi pi-trash"
                className="p-button-text p-button-danger"
                onClick={() => handleRemoveFile(item.name)}
              />
            </div>
          )}
        />
      </div>

      <Dialog
        header="Categoria de Arquivo"
        visible={showDialog}
        onHide={() => setShowDialog(false)}
        footer={
          <div>
            <Button
              label="Confirmar"
              icon="pi pi-check"
              onClick={handleCategoriaConfirm}
              disabled={!categoria}
            />
          </div>
        }
      >
        <div className="p-field">
          <label>Selecione a Categoria:</label>
          <div className="p-formgroup-inline">
            <Button
              label="Arquivo Principal"
              className={`p-button ${
                categoria === "Principal"
                  ? "p-button-success"
                  : "p-button-outlined"
              }`}
              onClick={() => setCategoria("Principal")}
            />
            <Button
              label="Arquivo Secundario"
              className={`p-button ${
                categoria === "Secundario"
                  ? "p-button-warning"
                  : "p-button-outlined"
              }`}
              onClick={() => setCategoria("Secundario")}
            />
          </div>
        </div>
        {categoria === "Secundario" && (
          <div className="p-field">
            <label htmlFor="localDestino">Local de Destino</label>
            <InputText
              id="localDestino"
              value={localDestino}
              onChange={(e) => setLocalDestino(e.target.value)}
              placeholder="Digite o local de destino"
            />
          </div>
        )}
      </Dialog>
    </Sidebar>
  );
};

export default VersaoModal;
