import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./index.css";
import { PageCadastro } from "./page/cadastro";
import { PageInicio } from "./page/inicio";
import { PageProduct } from "./page/produtos";
import { Atualizacao } from "./page/atualizacao";

import "primereact/resources/themes/saga-blue/theme.css"; // Escolha o tema desejado
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/empresa/:codigo" element={<PageProduct />} />
        <Route path="/atualizacao" element={<Atualizacao />} />
        <Route path="/" element={<PageInicio />} />
        <Route path="*" element={<PageInicio />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);
