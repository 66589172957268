import React, { useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import useFetch from "../../../component/conexao";

function TabelaFaturas({ type }) {
  const { data, loadingHoje, errorHoje } = useFetch(
    `https://goopedir.com/ws/v2/fatura-em-aberto.php?type=${type}`
  );
  const [globalFilter, setGlobalFilter] = useState("");

  if (loadingHoje) return <p>Carregando...</p>;
  if (errorHoje) return <p>Erro ao carregar dados!</p>;

  // Template para formatação do campo Vencimento
  const vencimentoTemplate = (rowData) => {
    const date = new Date(rowData.duedate);
    return date.toLocaleDateString("pt-BR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  };

  // Template para o Status
  const statusTemplate = (rowData) => {
    return new Date(rowData.duedate) < new Date() ? "Vencida" : "Em Aberto";
  };

  return (
    <div style={{ width: "100%" }}>
      {/* Campo de Pesquisa */}
      <div style={{ marginBottom: "1rem", display: "flex", width: "100%" }}>
        <span
          style={{ flex: 1, position: "relative" }}
          className="p-input-icon-left"
        >
          <i
            className="pi pi-search"
            style={{
              position: "absolute",
              left: "10px",
              top: "50%",
              transform: "translateY(-50%)",
            }}
          />
          <InputText
            value={globalFilter}
            onChange={(e) => setGlobalFilter(e.target.value)}
            placeholder="Pesquisar..."
            style={{ width: "100%", paddingLeft: "2.5rem" }}
          />
        </span>
      </div>

      {/* Tabela */}
      <DataTable
        value={data}
        paginator
        rows={10}
        globalFilter={globalFilter}
        responsiveLayout="scroll"
        style={{ width: "100%" }}
        emptyMessage="Nenhuma fatura encontrada."
      >
        <Column field="prefix" header="Fatura #" />
        <Column field="company" header="Cliente" />
        <Column field="duedate" header="Vencimento" body={vencimentoTemplate} />
        <Column
          field="total"
          header="Total"
          body={(rowData) => `R$ ${rowData.total}`}
        />
        <Column header="Status" body={statusTemplate} />
      </DataTable>
    </div>
  );
}

export default TabelaFaturas;
